import React, { FC, useRef, useEffect } from "react";

interface IProps {
  label: string;
  value: number;
  min: number;
  max: number;
  strokeColor: string;
  delta?: number;
  isPercentage?: boolean;
  big?: boolean;
  // Property to control string of statistics to => of
  of?: boolean;
}

const ProgressLoader: FC<IProps> = ({
  label = "Progress",
  value,
  min,
  max,
  strokeColor,
  isPercentage,
  big,
  delta,
  of
}) => {
  const circle = useRef<SVGCircleElement>();
  const dimension = big ? 200 : 160;
  const strokeWidth = 2;
  const radius = dimension / 2 - strokeWidth * 2;

  useEffect(() => {
    if (circle) {
      const percentage = isPercentage ? value / 100 : value / max;
      const radius = circle.current.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      circle.current.style.strokeDasharray = `${circumference} ${circumference}`;
      const offset = circumference - percentage * circumference;
      circle.current.style.strokeDashoffset = offset.toString();
    }
  }, [value]);

  const displayDelta = () => {
    if (delta) {
      return delta > 0 ? `+${delta}` : delta;
    }
    return null;
  };

  return (
    <div className="loader">
      <div className="loader-rings-container">
        <svg
          className="loader-rings"
          style={{
            width: dimension,
            height: dimension,
            stroke: strokeColor
          }}
        >
          <circle
            className="loader-ring"
            ref={circle}
            r={radius}
            cx={dimension / 2}
            cy={dimension / 2}
            strokeDashoffset={radius * 2 * Math.PI}
          />
          <circle
            className="loader-ring"
            stroke="#dadada"
            strokeOpacity="0.3"
            r={radius}
            cx={dimension / 2}
            cy={dimension / 2}
          />
        </svg>
        <div className={`loader-text-container ${big ? "big" : ""}`}>
          <div className="loader-text">{label}</div>
          <div className="loader-score">
            {value ? value.toFixed(0) : 0} {isPercentage && "%"}
          </div>
          <div className="loader-text">
            {min} {of ? "of" : "to"} {max}
          </div>
          {delta !== 0 && <div className="delta">{displayDelta()}</div>}
        </div>
      </div>
    </div>
  );
};

export default ProgressLoader;
