import React, { FC } from "react";

import ProgressLoader from "components/Shared/ProgressLoader/ProgressLoader";
import Skill from "components/Shared/Skill/Skill";
import Button from "components/Shared/Button/Button";
import colors from "constants/colors";

interface IProps {
  header: string;
  value: number;
  min: number;
  max: number;
  skills: Skill[];
  onClick?: () => void;
  delta?: number;
  percent?: boolean;
  chartLabel?: string;
}

const BAR_COLORS = [
  colors.lightGreen,
  colors.lightBlue,
  colors.orange,
  colors.yellow,
  colors.violet
];

const Stats: FC<IProps> = ({
  header,
  value,
  min,
  max,
  skills,
  onClick,
  delta,
  percent,
  chartLabel
}) => {
  return (
    <div className="stats-container">
      <h3 className="stats-header">{header}</h3>
      <div className="stats">
        <div>
          <ProgressLoader
            label={chartLabel}
            value={value}
            min={min}
            max={max}
            strokeColor={colors.studentBlue}
            delta={delta}
            isPercentage={percent}
            big
            of
          />
          <div className="stats-additional-button">
            {onClick && <Button name="More stats" onClick={onClick} />}
          </div>
        </div>
        <div className="stats-skills">
          {skills?.map(({ id, header, progress, additional, description }, index) => (
            <Skill
              key={id}
              id={id}
              header={header}
              progress={progress}
              additional={additional}
              description={description}
              fillColor={BAR_COLORS[index]}
            />
          ))}
        </div>
        {onClick && <Button name="More stats" onClick={onClick} />}
      </div>
    </div>
  );
};

export default Stats;
