import React, { FC } from "react";
import { v4 as uuid } from "uuid";
import Link from "./Link";

interface IProps {
  tabs: NavTab[];
  activeTab: NavTab;
  onTabChange: (tab: NavTab) => void;
}

const NavTabs: FC<IProps> = ({ tabs, activeTab, onTabChange }) => {
  return (
    <nav className="nav-additional" style={{ position: "initial" }}>
      <ul className="menu-additional">
        {tabs.map((tab) => (
          <Link
            label={tab.label}
            active={tab.id === activeTab.id}
            onClick={() => onTabChange(tab)}
            key={tab.id}
            inActiveStyle={tab.disabled}
          />
        ))}
      </ul>
    </nav>
  );
};

export default NavTabs;
