import React, { FC } from "react";

import Icon from "components/Shared/Icons/Icon/Icon";
import { genClasses } from "utils/textHelpers";

interface IProps {
  isExpanded: boolean;
  withWorkbook?: boolean;
  onClick?: () => void;
}

const Dropdown: FC<IProps> = ({ isExpanded, withWorkbook, onClick }) => {
  return (
    <div
      className={genClasses("dropdown-container", {
        "dropdown-container--withWorkbook": withWorkbook,
        "dropdown-container--expanded": isExpanded
      })}
      onClick={onClick}
    >
      <span style={{ position: "relative", bottom: 3 }}>
        <Icon icon="expand_more" />
      </span>
    </div>
  );
};

export default Dropdown;
