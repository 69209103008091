import React, { useState, useEffect } from "react";

import Stats from "components/Shared/Stats/Stats";
import SearchBar from "components/Shared/SearchBar/SearchBar";
import SubjectPage from "components/Shared/Scores/SubjectPage/SubjectPage";
import StrengthsWeaknessesNavBar from "./StrengthsWeaknessesNavBar/StrengthsAndWeaknessesNavBar";

const StrengthsAndWeaknesses: React.FC<any> = ({
  categories,
  totalScore,
  delta,
  subjects,
  percent
}) => {
  const [detailedScores, setDetailedScores] = useState();
  const [activeSlide, setActiveSlide] = useState("");
  const [search, setSearch] = useState("");
  const [skills, setSkills] = useState<Skill[]>(null);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (categories) {
      const initialCategory = categories.length ? categories[0].name : "";
      setActiveSlide(initialCategory);

      const s = categories.find((category) => category.name === initialCategory);
      setDetailedScores(s);

      setSkills(
        categories.map(({ id, name, score: { correct, percent_correct, total } }) => ({
          id,
          header: `${name} Questions`,
          progress: percent_correct,
          additional: `${Math.round(percent_correct)}%`,
          description: `${correct} of ${total}`
        }))
      );

      setStats(
        categories.reduce(
          (acc, c) => ({
            correct: acc.correct + c.score.correct,
            total: acc.total + c.score.total
          }),
          {
            correct: 0,
            total: 0
          }
        )
      );
    }
  }, [categories]);

  useEffect(() => {
    setDetailedScores(categories.find((category) => category.name === activeSlide));
  }, [activeSlide]);

  const handleSetActiveSlide = (activeSlide) => {
    setSearch("");
    setActiveSlide(activeSlide);
  };

  const handleClearSearch = () => {
    setSearch("");
    setDetailedScores(categories.find((category) => category.name === activeSlide));
  };

  const filterChildrenByName = (children, phrase: string) => {
    if (!children) return null;
    return children
      .map((child) => {
        if (child.name.toLowerCase().includes(phrase.toLowerCase())) {
          let c = {};
          if (child.hasOwnProperty("children")) {
            c = {
              children: filterChildrenByName(child.children, phrase)
            };
          }

          return { ...child, ...c };
        }
        if (child.hasOwnProperty("children")) {
          const children = filterChildrenByName(child.children, phrase);
          return children.length ? { ...child, children } : false;
        } else {
          return child.name.toLowerCase().includes(phrase.toLowerCase()) ? child : false;
        }
      })
      .filter((child) => child !== false);
  };

  const handleSearch = (e) => {
    let value = e.target.value || e;
    if (typeof value !== "string") {
      handleClearSearch();
    } else {
      setSearch(value);
      if (value.length < 2) {
        return false;
      } else if (value !== "") {
        mapNewCategories(value);
      }
    }
  };

  const mapNewCategories = (value: string) => {
    const s = categories.find((category) => category.name === activeSlide);
    if (value === "") {
      setDetailedScores(s);
      return false;
    }
    const newChildren = filterChildrenByName(s.children, value);
    const newList = {
      ...s,
      children: newChildren
    };
    setDetailedScores(newList);
  };

  return (
    <div className="weaknesses">
      <div
        className="card-block weaknesses-sub-container"
        style={{
          paddingRight: 0
        }}
      >
        {categories && subjects && detailedScores ? (
          <>
            <div className="strengths-and-weaknesses-navbar-with-search">
              <StrengthsWeaknessesNavBar
                activeSlide={activeSlide}
                onSetActiveSlide={handleSetActiveSlide}
                categories={categories}
              />
              <div className="weaknesses-content">
                <SearchBar value={search} onChange={handleSearch} onClear={handleClearSearch} />
              </div>
            </div>

            <div className="card weaknesses-card">
              <div className="card-content">
                <div className="main-slick">
                  <SubjectPage search={search} scores={detailedScores} />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {skills && (
        <Stats
          header="Performance by Subjects"
          min={stats.correct}
          max={stats.total}
          value={totalScore}
          skills={skills}
          delta={delta}
          percent={percent}
          chartLabel="Questions answered correctly"
        />
      )}
    </div>
  );
};

export default StrengthsAndWeaknesses;
