import { FC } from "react";

import InfoIcon from "components/Shared/Icons/InfoIcon/InfoIcon";
import ProgressLoader from "components/Shared/ProgressLoader/ProgressLoader";
import { SHOW_HELP_ICONS } from "constants/variables";

interface IProps {
  subjects: Subject[];
  courseSubjects: CourseStats;
  big?: boolean;
  activeCourse?: Course;
}

const colorStrokesSAT = ["#35a6af", "#55b24b", "#4785f4"];
const colorStrokesACT = ["#55b24b", "#35a6af", "#4785f4", "#35a6af"];

const SectionScores: FC<IProps> = ({ subjects, courseSubjects, big, activeCourse }) => {
  return (
    courseSubjects && (
      <div className="summary-scores">
        <div className="summary-header-container">
          <h2>Sections Score</h2>
          <p className="summary-annotation">From all tests</p>
        </div>
        <div className="summary-loaders">
          {subjects &&
            subjects?.map(({ name, current_score, delta }, index) => (
              <div className="summary-loader " key={index}>
                <ProgressLoader
                  label={name === "Writing and Language" ? "Writing" : name}
                  value={current_score}
                  min={(courseSubjects[name] && courseSubjects[name].min) || 0}
                  max={(courseSubjects[name] && courseSubjects[name].max) || 0}
                  strokeColor={
                    activeCourse?.course_type === "ACT"
                      ? colorStrokesACT[index]
                      : colorStrokesSAT[index]
                  }
                  delta={delta}
                  big={true}
                />
                {SHOW_HELP_ICONS && (
                  <InfoIcon info="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde, provident." />
                )}
              </div>
            ))}
        </div>
      </div>
    )
  );
};

export default SectionScores;
