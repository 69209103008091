import React, { FC } from "react";
import { useSelector } from "react-redux";
import { makeSelectActiveCourse } from "Store/user/selectors";
import { genClasses } from "utils/textHelpers";
import { SUBJECTS_ORDER } from "constants/subjects";

interface IProps {
  activeSlide: string;
  onSetActiveSlide: (e: string) => void;
  categories: SubjectCategory[];
}

const StrengthsWeaknessesNavBar: FC<IProps> = ({ activeSlide, onSetActiveSlide, categories }) => {
  const activeCourse = useSelector(makeSelectActiveCourse);
  return (
    <div className="row mb-0">
      <div className="nav-slick nav-tab strengths-and-weaknesses-nav-bar">
        {categories
          .sort(
            (a, b) =>
              SUBJECTS_ORDER[activeCourse.course_type].indexOf(a.name) -
              SUBJECTS_ORDER[activeCourse.course_type].indexOf(b.name)
          )
          .map((category) => (
            <div key={category.id} className="navbar-link-container">
              <div
                className={genClasses("navbar-link", {
                  "navbar-link--active": activeSlide === category.name
                })}
                onClick={(event) => {
                  event.preventDefault();
                  onSetActiveSlide(category.name);
                }}
              >
                {category.name}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default StrengthsWeaknessesNavBar;
