import React, { FC } from "react";

import StatusBar from "components/Shared/StatusBar/StatusBar";

interface IProps {
  id?: string;
  header: string;
  progress: number;
  additional?: string;
  description: string;
  className?: string;
  style?: React.CSSProperties;
  fillColor?: string;
}

const Skill: FC<IProps> = ({ header, progress, additional, description, className, fillColor }) => {
  return (
    <div className={`skill ${className || ""}`}>
      <p className="skill-header">{header}</p>
      <StatusBar progress={progress} fillColor={fillColor} />
      <div className="skill-numbers">
        <p className="skill-number--additional">{additional && `${additional}`}</p>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Skill;
