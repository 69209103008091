import React, { FC, useEffect, useState } from "react";

import GroupBlock from "components/Shared/CollapsableBlocks/GroupBlock/GroupBlock";

interface IProps {
  scores: any;
  search?: string;
}

const SubjectPage: FC<IProps> = ({ scores, search }) => {
  const [parentScores, setParentScores] = useState([]);

  useEffect(() => {
    if (scores) {
      setParentScores(scores);
    }
  }, [scores]);

  const mapGroupBlock = () => {
    if (parentScores instanceof Array && parentScores.length !== 0) {
      const regex = new RegExp(search, "i");
      const searchingResults = parentScores.filter((group) => {
        if (search) {
          if (
            regex.test(group.name) ||
            (group.children &&
              group.children.some((child) => {
                const childChildrenResult =
                  child.children && child.children.some((child) => regex.test(child.name));
                return regex.test(child.name) || childChildrenResult;
              }))
          ) {
            return true;
          }
        }
      });
      if (search) {
        if (searchingResults.length > 0) {
          return searchingResults.map((group) => <GroupBlock data={group} key={group.id} />);
        } else {
          return <h3 style={{ textAlign: "center" }}>There are no search results.</h3>;
        }
      } else {
        return parentScores.map((group) => <GroupBlock data={group} key={group.id} />);
      }
    } else {
      return <GroupBlock data={scores} />;
    }
  };

  return (
    <div className="slide">
      <div className="container-sm">
        <div className="graphs-section graphs-students">{mapGroupBlock()}</div>
      </div>
    </div>
  );
};

export default SubjectPage;
