import React, { FC } from "react";
import { genClasses } from "utils/textHelpers";

interface IProps {
  label: string;
  active: boolean;
  onClick: () => void;
  inActiveStyle?: boolean;
}

const Link: FC<IProps> = ({ label, active, onClick, inActiveStyle }) => {
  return (
    <li
      className={genClasses("navbar-button-container ", {
        "navbar-button-container--active": active,
        "navbar-button-container--inActive": inActiveStyle
      })}
      onClick={onClick}
    >
      <a className="navbar-button" role="button" tabIndex={0}>
        {label}
      </a>
    </li>
  );
};

export default Link;
