export const SUBJECTS_ORDER = {
  ACT: ["English", "Math", "Reading", "Science"],
  dACT: ["English", "Math", "Reading", "Science"],
  SAT: ["Reading", "Writing and Language", "Math"],
  dSAT: ["Reading & Writing", "Math"]
};

export const BUBBLE_SCHEME: IProblemBubbles[] = [
  {
    id: 0,
    label: "A",
    altLabel: "F",
    value: "A"
  },
  {
    id: 1,
    label: "B",
    altLabel: "G",
    value: "B"
  },
  {
    id: 2,
    label: "C",
    altLabel: "H",
    value: "C"
  },
  {
    id: 3,
    label: "D",
    altLabel: "J",
    value: "D"
  },
  {
    id: 4,
    label: "E",
    altLabel: "K",
    value: "E"
  }
];
