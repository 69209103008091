import React, { FC } from "react";

import ProgressLoader from "components/Shared/ProgressLoader/ProgressLoader";
import InfoIcon from "components/Shared/Icons/InfoIcon/InfoIcon";
import colors from "constants/colors";
import { SHOW_HELP_ICONS } from "constants/variables";

interface IProps {
  header: string;
  annotation: string;
  value: number;
  delta: number;
  min: number;
  max: number;
  showGraph?: boolean;
}

const TotalScore: FC<IProps> = ({ header, annotation, value, delta, min, max, showGraph }) => {
  return (
    <div className="summary-scores">
      <div className="summary-header-container">
        <h2>{header}</h2>
        <p className="summary-annotation">{annotation}</p>
      </div>
      {showGraph && (
        <div className="summary-loaders ">
          <ProgressLoader
            label="Total Score"
            value={value}
            min={min}
            max={max}
            strokeColor={colors.studentBlue}
            delta={delta}
            big
          />
          {SHOW_HELP_ICONS && (
            <InfoIcon info="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde, provident." />
          )}
        </div>
      )}
    </div>
  );
};

export default TotalScore;
