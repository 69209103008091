import React, { FC, useState } from "react";

import SubBlock from "components/Shared/CollapsableBlocks/SubBlock";
import Dropdown from "components/Shared/Dropdown/Dropdown";
import StatusBar from "components/Shared/StatusBar/StatusBar";

import { getScoreStatus } from "components/Shared/CollapsableBlocks/GetScoreStatus";
import colors from "constants/colors";
import { genClasses } from "utils/textHelpers";

interface IProps {
  data: StrengthsAndWeakness;
}

const GroupBlock: FC<IProps> = ({ data: { name, score, children } }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const mapGroupRow = () => {
    if (children.length !== 0) {
      return children.map((child) => <SubBlock data={child} key={child.id} level={1} />);
    }
    return null;
  };
  return (
    <div className="group-block">
      <div
        className="group-block-container"
        style={{
          borderBottom: isExpanded && children && "1px dotted grey",
          paddingBottom: isExpanded && children && "10px"
        }}
      >
        <div className="group-block-header-container">
          <div className={getScoreStatus(parseInt(score.percent_correct.toFixed(0), 10))}>
            {score.percent_correct.toFixed(0)}%
          </div>
          <div className="group-block-header">{name}</div>
        </div>
        <div className="group-block-stats-container">
          <StatusBar progress={(score.correct / score.total) * 100} />
          <div className="group-block-stats">{`${score.correct} of ${score.total}`}</div>
          {children && (
            <Dropdown isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)} />
          )}
        </div>
      </div>
      <div
        className={genClasses("group-block-children", {
          "group-block-children--expanded": isExpanded
        })}
      >
        {children?.length > 0 ? (
          mapGroupRow()
        ) : (
          <div style={{ textAlign: "center", color: colors.grey }}>No categories</div>
        )}
      </div>
    </div>
  );
};

export default GroupBlock;
