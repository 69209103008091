import React, { FC } from "react";

import StatusBar from "components/Shared/StatusBar/StatusBar";

import { getScoreStatus } from "./GetScoreStatus";

interface IProps {
  data: StrengthsAndWeakness;
  level: number;
}

const SubBlock: FC<IProps> = ({ data, level }) => {
  const mapSubRow = () => {
    if (data.children) {
      return data.children.map((children) => (
        <SubBlock data={children} key={children.id} level={level+1} />
      ));
    }
    return null;
  };
  return (
    <>
      {data?.score && (
        <div
          className="group-block-container"
          style={{
            paddingLeft: `${10 * level}px`
          }}
        >
          <div className="group-block-header-container">
            <div className={getScoreStatus(parseInt(data?.score?.percent_correct.toFixed(0), 10))}>
              {data?.score?.percent_correct.toFixed(0)}%
            </div>
            <div className="group-block-header">{data.name}</div>
          </div>
          <div className="group-block-stats-container">
            <StatusBar progress={(data.score.correct / data.score.total) * 100} />
            <div className="group-block-stats">{`${data.score.correct} of ${data.score.total}`}</div>
            <div className="group-block-dropdown-space" />
          </div>
        </div>
      )}
      {mapSubRow()}
    </>
  );
};

export default SubBlock;
